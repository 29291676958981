import * as React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay, EffectFade } from "swiper"

import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo"
import FlexibleContent from "../components/FlexibleContent/FlexibleContent"
import "swiper/css"
import "swiper/css/effect-fade"

const IndexPage = ({ data }) => {
  const { hero_items, flexible_content, meta_title, meta_description } =
    data.home.fields

  return (
    <Layout>
      <Seo title={meta_title || "Home"} description={meta_description} />
      {hero_items?.length && (
        <Swiper
          modules={[Autoplay, EffectFade]}
          effect="fade"
          autoplay={{ delay: 8000 }}
          loop={true}
        >
          {hero_items.map(({ image, title, logo }) => {
            if (!image) return null

            return (
              <SwiperSlide key={title}>
                <div className="h-64 sm:aspect-w-5 sm:aspect-h-2 sm:h-auto">
                  <GatsbyImage
                    className="h-full w-full object-cover"
                    image={getImage(image.gatsbyImageData)}
                    aspectRatio={5 / 2}
                    alt={image.alt || ""}
                    style={{ position: "absolute" }} // Override Gatsby styling
                  />
                </div>
                <div className="absolute inset-x-0 bottom-0 mx-gutter mb-12 lg:mb-16 xl:mb-24">
                  <div className="sm:flex sm:items-end sm:justify-between">
                    {title && (
                      <h2 className="max-w-3xl text-3xl font-semibold tracking-wide text-cloud-white sm:text-4xl lg:text-6xl xl:text-7xl">
                        {title}
                      </h2>
                    )}
                    {logo && (
                      <div className="mt-6 sm:mt-0 sm:ml-6 lg:text-right">
                        <GatsbyImage
                          objectFit="contain"
                          className="h-12 w-28 lg:h-24 lg:w-56"
                          image={getImage(logo.gatsbyImageData)}
                          alt={logo.alt || ""}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </SwiperSlide>
            )
          })}
        </Swiper>
      )}

      {flexible_content &&
        flexible_content.map(slice => (
          <FlexibleContent key={slice.id} {...slice} />
        ))}
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query HomeQuery {
    home: prismicHome {
      fields: data {
        hero_items {
          image {
            gatsbyImageData
            alt
          }
          title
          logo {
            gatsbyImageData
            alt
          }
        }
        flexible_content {
          ... on PrismicHomeDataFlexibleContentBrandsSection {
            id
            slice_type
            fields: primary {
              layout
              title
              content {
                richText
              }
              cta_text
              cta_link {
                ...linkFragment
              }
            }
            brands: items {
              brand {
                document {
                  ...brandsFragment
                }
              }
            }
          }
          ... on PrismicHomeDataFlexibleContentCtaCardsSection {
            id
            slice_type
            cta_cards: items {
              cta_card {
                document {
                  ...ctaCardsFragment
                }
              }
            }
          }
          ... on PrismicHomeDataFlexibleContentInvestorCentreSummarySection {
            id
            slice_type
          }
        }
        meta_title
        meta_description
      }
    }
  }
`
